import { createRouter, RouteRecordRaw, createWebHistory } from "vue-router";
import Session from "supertokens-web-js/recipe/session";

const routes = [
  {
    path: "/",
    name: "default",
    component: () => import("@/layouts/homepageLayout.vue"),
    children: [
      {
        path: "/",
        name: "homepage",
        component: () => import("@/views/landingpage/homepage/index.vue"),
      },
      {
        path: "services",
        name: "Services page",
        component: () => import("@/views/landingpage/services/index.vue"),
      },
      {
        path: "about-us",
        name: "About us page",
        component: () => import("@/views/landingpage/aboutus/index.vue"),
      },
      {
        path: "download",
        name: "Download page",
        component: () => import("@/views/landingpage/downloadPage/index.vue"),
      },
      {
        path: "contact-us",
        name: "Contact us page",
        component: () => import("@/views/landingpage/contactUs/index.vue"),
      },
      {
        path: "privacy-policy",
        name: "Privacy Policy",
        component: () => import("@/views/landingpage/privacyPolicy/index.vue"),
      },
      {
        path: "terms-and-conditions",
        name: "Term and Conditions",
        component: () =>
          import("@/views/landingpage/termsAndCondition/index.vue"),
      },
    ],
  },
  {
    path: "/driver/registration/login",
    name: "login",
    component: () => import("@/views/landingpage/maintenance.vue"),
  },
  {
    path: "/driver/registration/confirm-otp",
    name: "Otp",
    component: () => import("@/views/driver/login/confirmOtp.vue"),
  },
  {
    path: "/driver/",
    name: "driver",
    component: () =>
      import("@/views/driver/pages/layout/registrationLayout.vue"),
    meta: {
      requireLogin: true,
    },
    children: [
      {
        path: "registration/personal-details",
        name: "personalDetails",
        component: () =>
          import(
            "@/views/driver/pages/registration/personalDetails/components/form.vue"
          ),
      },
      {
        path: "registration/contact-person",
        name: "contactPerson",
        component: () =>
          import(
            "@/views/driver/pages/registration/contactPerson/components/form.vue"
          ),
      },
      {
        path: "registration/vehicles-information",
        name: "vehiclesInfo",
        component: () =>
          import(
            "@/views/driver/pages/registration/vehiclesInfo/components/form.vue"
          ),
      },
      {
        path: "registration/documents",
        name: "documents",
        component: () =>
          import(
            "@/views/driver/pages/registration/documents/components/form.vue"
          ),
      },
      {
        path: "registration/success",
        name: "success",
        component: () =>
          import(
            "@/views/driver/pages/registration/successPage/components/success.vue"
          ),
      },
    ],
  },
  {
    path: "/driver/registration/application-status",
    name: "applicationStatus",
    component: () =>
      import(
        "@/views/driver/pages/registration/applicationStatus/components/index.vue"
      ),
  },
  {
    path: "/helpcenter",
    name: "helpcenter",
    component: () => import("@/layouts/homepageLayout.vue"),
    children: [
      {
        path: "/helpcenter",
        name: "helpcenter",
        component: () => import("@/views/landingpage/helpcenter/index.vue"),
      },
      {
        path: "account",
        name: "help center accounts",
        component: () => import("@/views/landingpage/helpcenter/accounts.vue"),
      },
      {
        path: "advisory",
        name: "help center advisory",
        component: () => import("@/views/landingpage/helpcenter/advisory.vue"),
      },
      {
        path: "helpguides",
        name: "help center help guides",
        component: () =>
          import("@/views/landingpage/helpcenter/helpguides.vue"),
      },
      {
        path: "offers",
        name: "offers page",
        component: () => import("@/views/landingpage/helpcenter/offers.vue"),
      },
      {
        path: "contacts",
        name: "contact page",
        component: () => import("@/views/landingpage/helpcenter/contacts.vue"),
      },
      {
        path: "answers/:tabName/:tabId/:id",
        name: "answers page",
        component: () =>
          import("@/views/landingpage/helpcenter/answersTemp/template.vue"),
      },
    ],
  },
  {
    path: "/helpcenter/account",
    name: "Helpcenter Answer",
    component: () => import("@/layouts/homepageLayout.vue"),
    children: [
      {
        path: "account-signin/:id",
        name: "accountSignin",
        component: () =>
          import("@/views/landingpage/helpcenter/accountAnswers/answerOne.vue"),
      },
      {
        path: "update-account/:id",
        name: "updateAccount",
        component: () =>
          import(
            "@/views/landingpage/helpcenter/accountAnswers/updateAccountTemp.vue"
          ),
      },
    ],
  },
  {
    path: "/helpcenter/advisory",
    name: "Advisory Answer",
    component: () => import("@/layouts/homepageLayout.vue"),
    children: [
      {
        path: "safety-precaution/:id",
        name: "advisoryAnswerOne",
        component: () =>
          import(
            "@/views/landingpage/helpcenter/advisoryAnswers/safetyPrecaution/answerTemplate.vue"
          ),
      },
    ],
  },
  {
    path: "/success/:title/:message",
    name: "payment success",
    component: () => import("@/views/payment/success.vue"),
  },
  {
    path: "/failed/:title/:message",
    name: "payment failed",
    component: () => import("@/views/payment/failed.vue"),
  },
  {
    path: "/cancelled/:title/:message",
    name: "payment cancelled",
    component: () => import("@/views/payment/cancelled.vue"),
  },
  {
    path: "/email-verified",
    name: "Email Verfication",
    component: () => import("@/views/email/verified.vue"),
  },
  {
    path: "/account-deletion",
    name: "Account Deletion",
    component: () => import("@/views/account/deletion.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@/views/landingpage/404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash == "#FourWheels") {
      return {
        el: "#FourWheels",
        top: 110,
        behavior: "smooth",
      };
    }
    if (to.hash == "#TwoWheels") {
      return {
        el: "#TwoWheels",
        top: 110,
        behavior: "smooth",
      };
    }
    if (to.hash == "#ComingSoon") {
      return {
        el: "#ComingSoon",
        top: 110,
        behavior: "smooth",
      };
    }
    return { top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const session = await Session.doesSessionExist();

  if (to.matched.some((record) => record.meta.requireLogin)) {
    // Check if Session is Active
    if (!session) {
      // Refresh if Session is not Active
      const refresh = await Session.attemptRefreshingSession();

      // Refresh Session
      if (!refresh) {
        // Redirect if Refresh is Failed

        next({ name: "login", query: { to: to.path } });
      } else {
        // Proceed if Session refresh
        next();
      }
    } else {
      // Proceed if you have session
      next();
    }
  } else {
    next();
  }
});

export default router;
