import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import "@/assets/tailwind.css";
import router from "./router";
import pinia from "@/store";
import VueHead from "vue-head";
import { plugin, defaultConfig } from "@formkit/vue";
import config from "@/formkit.config";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faL } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { far } from "@fortawesome/free-regular-svg-icons";
/* import specific icons */
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";

/* add icons to the library */
library.add(faUserSecret);
library.add(fas);
library.add(faL);
library.add(far);

import "@fontsource/poppins/100.css";
import "@fontsource/poppins/200.css";
import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/poppins/800.css";
import "@fontsource/poppins/900.css";

import SuperTokens from "supertokens-web-js";
import Session from "supertokens-web-js/recipe/session";
import Passwordless from "supertokens-web-js/recipe/passwordless";

// Init Super Tokens
SuperTokens.init({
  appInfo: {
    apiDomain: process.env.VUE_APP_SUPERTOKENS_URL,
    apiBasePath: "/api/auth",
    appName: "Utol",
  },
  recipeList: [
    Session.init({
      tokenTransferMethod: "header",
    }),
    Passwordless.init(),
  ],
});

createApp(App)
  .use(router)
  .use(pinia)
  .use(plugin, defaultConfig(config))
  .use(VueHead)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
